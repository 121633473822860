import React from "react";
import adaptation from '../imgs/images/icons8-adaptation-64.png'
import conproduction from '../imgs/images/icons8-content-production-66.png'
import conwriting from '../imgs/images/icons8-content-writing-681.png'
import interactive from '../imgs/images/icons8-interactive-64.png'
import referral from '../imgs/images/icons8-referral-64.png'
import digitalmarketing from '../imgs/images/icons8-selecting-that-compatible-candidate-for-supervisor-job-64.png'
import '../css/services.css'

export const Services = () => {

  

  return (
    <div>
    
    <div className="servicepage">
      <h1>SERVICES</h1>
    <div className="yellow-back">
      <div className="services">
        <div className="logo-background"><img className="service-logo" alt="" src={conproduction} /></div>
        <p>Original Content </p><p> Production &</p>
        <p>Co-Production</p>
      </div>

      <div className="services">
        <div className="logo-background"><img className="service-logo" alt="" src={adaptation} /></div>
        <p>Adaptation &</p>
        <p>Remake Rights</p>
      </div>

      <div className="services">
        <div className="logo-background"><img className="service-logo" alt="" src={conwriting} /></div>
        <p>Content Reselling &</p>
        <p> Cross Monetisation</p>
      </div>
   

    <div className="services">
        <div className="logo-background"><img className="service-logo" alt="" src={interactive} /></div>
        <p>Web3-Verse</p>
        <p> Blockchain & NFT</p>
      </div>
    

    <div className="services">
        <div className="logo-background"><img className="service-logo" alt="" src={referral} /></div>
        <p>Financial Assesent &</p>
        <p> Referral For Content Production</p>
      </div>
    

    <div className="services">
        <div className="logo-background"><img className="service-logo" alt="" src={digitalmarketing} /></div>
        <p>Digital Marketing &</p>
        <p>Content Placement</p>
      </div>

    </div>
    </div>

    
   
    </div>
  )

}
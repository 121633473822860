import React, { useRef, useEffect, useState } from 'react';
import '../css/glassmorphi.css';
import logo from '../imgs/LME.jpg';


export const Glassmorphi = () => {
  const profileCardRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (profileCardRef.current) {
        const rect = profileCardRef.current.getBoundingClientRect();
        const isInViewport = rect.top >= 0 && rect.bottom <= window.innerHeight;
        if (isInViewport) {
          setIsVisible(true);
        } 
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      {/* Profile Card */}
      <div className='align-bg'>
        <div className='profile-card-background'>
          <div className='back-card'></div>
          <div className='img-holder'>
          <img src={logo} alt="LOTUS META ENTERTAINMENT,LME" />
          </div>
          <div
            className={`profile-card ${isVisible ? 'show-content' : ''}`}
            ref={profileCardRef}
          >
            <div className='profile-card-content'>
              <h2>LOTUS META ENTERTAINMENT <strong className='primay-White'>(LME)</strong></h2>
              <p>  Lotus meta entertainment LLP was initially promoted by legendary actor Kamal Hassan along with Mr Suresh Iyer to explore and venture beyond the existing and emerging contours of entertainment industry. Forayed in the territory of web3 – Metaverse along with Blockchain & NFT creation & auctioning, trade. To start with LME successfully launched the Vikram film NFT and metaverse.</p><br />
              <p> Driven solely by Suresh Iyer and LME IS collaborating with stalwarts, and Industry Professionals (national and international) to realise its original objectives in Media & Entertainment Sector. Driving the content in the Local Market and International & Decentralised Market place.
              </p><br />
               <p>
With the group of professionals directly and indirectly involved LME always believed in the vision, art of seeing what is invisible to other; in the mission, just do not dream, bring in the motion – action, with an aim choose your aim amidst lot in there.  
 </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

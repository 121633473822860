import React from 'react'
import '../css/evangelist.css'
import pawan from '../imgs/pawan.jpeg';
import rakesh from '../imgs/rakesh.jpeg';

export const Evangelist = () => {
  return (
    <div>
    <div className='evangelist-container'>
      <div className='line'></div>

      <h1>KEY COLLABORATORS</h1>

  <div className='inside-container'>
        
    <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <img src={pawan} alt="lME,Lotus Meta Entertainment, Pawan Jailkhani"  />
          <div className="click-me-btn"> 

            <h3> Pawan Jailkhani</h3>
            
            Click for info
            
            </div>
        </div>
        <div className="flip-card-back">
          <div className='align'>
          <h3>Pawan Jailkhani</h3>
          <p>Pawan jailkhani is a true- blue expert Brand specialist, who has mainlines on Ad-tech and content development and over the past 3 decades has worked with some of the best known brands including Zee entertainment and 9X media.</p>
          <p>A prolific angel investor with keen interest in tech/ data and content, Pawan has invested in several tech startups and has founded the first Global Ad tech platform for multi-lingual consumer. 
          Passionate about stories and consumerism, he has developed several verticals of content development and syndication across India and North America.</p>
          </div>
        </div>
      </div>
    </div>


    <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <img src={rakesh} alt="lME,Lotus Meta Entertainment,Rakesh Juneja"  />
          <div className="click-me-btn">
          <h3> Rakesh Juneja</h3>
            Click for info</div>
        </div>
        <div className="flip-card-back">
        <div className='align'>
          <h3>Rakesh Juneja</h3>
          <p>Rakesh Juneja, the visionary producer who assumes a pivotal role in shaping the cinematic narrative from conception to completion. 
          Rakesh's journey into film production began with early training under Ad Film Makers & Music Video Producers, instilling in him an inherent understanding of detailing, pre-production intricacies, and a commitment to aesthetic values.</p>
          <p>Beyond his past experiences, whether it was OPTIMYSTIX or Vikram Bhatt’s film production house  VSB Productions LLP creatively headed by the legend MAHESH BHATT.</p>
        </div>
        </div>
      </div>
    </div>

    </div>
        </div>    
    </div>
  )
}
